import clsx from "clsx";

import { Row } from "@/components/containers";
import { ChatBubbleIcon } from "@/components/icons";

import satisfactionImage from "$/images/mile/mileprogram-satisfaction-graph.svg";

import styles from "./UserVoice.module.scss";

export function UserVoices() {
  return (
    <div className={styles.root}>
      <div className={clsx(styles.titleChip, "clear__sp")}>参加者の声</div>
      <div className={styles.chatBubbles}>
        <div className={clsx(styles.titleChip, "clear__pc")}>参加者の声</div>
        <Row>
          <Row className={styles.firstChatContainer}>
            <ChatBubbleIcon
              color="#00508c"
              className={clsx(styles.chatBubble, styles.chatBubbleNormal)}
            />
            <Row className={styles.chatContent}>
              <p>{`普段聞けない開発の\n裏側や社員の皆様の\nお話を聞くことができ\n興味深かったです。`}</p>
            </Row>
          </Row>
        </Row>
        <Row>
          <Row className={styles.secondChatContainer}>
            <ChatBubbleIcon
              color="#8CB4DC"
              className={clsx(styles.chatBubble, styles.chatBubbleReverse)}
            />
            <Row className={styles.chatContent}>
              <p>{`チャットを通して\n社員の方や会員のみなさんとコミュニケーションが取れてとてもよかったです。`}</p>
            </Row>
          </Row>
        </Row>
        <Row>
          <img src={satisfactionImage.src} alt="満足度94%" className={styles.satisfactionImage} />
        </Row>
      </div>
      <p className={clsx("text__gray__dark", "text__s", "mg__top__m", styles.annotation)}>
        ※BASE FOOD SUMMIT Vol.3のアンケート結果をもとに作成
      </p>
    </div>
  );
}
