"use client";

import clsx from "clsx";

import { Button, Column, MoreVertIcon, Row, Scroll } from "@/components";
import { useParsedGetMiles } from "@/queries";
import rankStyles from "@/style/rankColor.module.scss";
import { numberFormat } from "@/utils";
import { objectEntries } from "@/utils/object";

import benefit01Image from "$/images/mile/mileprogram-benefit-01.svg";
import benefit02Image from "$/images/mile/mileprogram-benefit-02.svg";
import benefit03Image from "$/images/mile/mileprogram-benefit-03.svg";

import styles from "./MileProgramBenefit.module.scss";
import { MileProgramFunContents } from "./MileProgramFunContents";
import { RankBenefitItem } from "./RankBenefitItem";
import { UserVoices } from "./UserVoices";
import { mileProgramSections } from "../consts";

const rankBenefits = {
  Business: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_business.svg",
    label: "ビジネスクラス以上",
    threshold: 20000,
    benefit: "アウトレットが利用できる",
    benefitImage: benefit01Image,
    benefitDescription:
      "リニューアルの際の旧型商品や賞味期限が短いアウトレット商品を、お得な価格で購入できるアウトレットショップがご利用いただけます。",
    caution: "※アウトレット開催時には、メールとマイページにて特設サイトをご案内します。",
  },
  First: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_first.svg",
    label: "ファーストクラス以上",
    threshold: 100000,
    benefit: "新商品が30%OFF",
    benefitImage: benefit02Image,
    benefitDescription: "新商品を発売開始から期間限定で、特別価格にてご購入いただけます。",
    caution: "",
  },
  Vip: {
    rankImage: "https://asset.basefood.co.jp/images/parts/badge_vip.svg",
    label: "VIPクラス限定",
    threshold: 200000,
    benefit: "限定イベントに参加できる",
    benefitImage: benefit03Image,
    benefitDescription:
      "VIPクラス会員限定の参加型オンラインイベント「BASE FOOD SUMMIT」にご招待。会員のみなさまやベースフードスタッフとの交流が楽しめます。",
    caution: "",
  },
} as const;

export function MileProgramBenefit(): React.ReactNode {
  const { isLegacySilverRankOrHigher } = useParsedGetMiles();
  return (
    <section id={mileProgramSections.Benefit.id} className={clsx(styles.container)}>
      <Column className={clsx(styles.header)}>
        <h3>Rank Benefits</h3>
        <h4>ランク特典</h4>
      </Column>
      {isLegacySilverRankOrHigher && (
        <Column className={clsx(styles.body)}>
          <div className={styles.alertMessage}>
            <p className="text__m text__bold text__red">
              旧マイルプログラムのダイヤモンド・ゴールドランクの方々の「新商品30%OFF」特典、シルバーランク以上の方々の「アウトレット」特典は、新マイルプログラム移行後も引き継がれます。
            </p>
          </div>
        </Column>
      )}
      <Column className={styles.body}>
        <p className={styles.description}></p>
        <div className={styles.benefitHeaders}>
          {objectEntries(rankBenefits).map(([rank, rankBenefit], index) => {
            const rankIndex = (index + 1).toString().padStart(2, "0");
            return (
              <Column key={rank} className={styles.rankBenefitItem}>
                <Row className={styles.benefitConditionCard}>
                  <img src={rankBenefit.rankImage} alt={rankBenefit.label} />
                  <Column>
                    <p className={clsx("text__m", rankStyles[`${rank}PrimaryFontColor`])}>
                      {rankBenefit.label}
                    </p>
                    <p className={clsx("text__s", rankStyles[`${rank}PrimaryFontColor`])}>
                      累計
                      <span className={clsx("text__bold", "text__l", styles.rankThreshold)}>
                        {numberFormat(rankBenefit.threshold)}
                      </span>
                      <span className="text__bold">マイル〜</span>
                    </p>
                  </Column>
                </Row>
                <Scroll to={`rank-benefits-${rank}`} className={styles.rankBenefit} smooth>
                  <Row align="center">
                    <Column className={styles.rankIndex}>
                      <p className={clsx("text__m", "text__bold")}>特典</p>
                      <p className={clsx("text__xxl", "text__bold")}>{rankIndex}</p>
                    </Column>
                    <p className={clsx("text__black", "text__m", "text__bold")}>
                      {rankBenefit.benefit}
                    </p>
                  </Row>
                  <div className={styles.rankBenefitDescriptionArrow} />
                </Scroll>
              </Column>
            );
          })}
        </div>
        <MoreVertIcon />
        <div className={styles.rankBenefitDetailContainer}>
          {/* 境界線が複雑なためmap関数で実装していません */}
          <RankBenefitItem
            rankName="Business"
            rankBenefit={rankBenefits.Business}
            index={0}
            qualifiedRanks={[rankBenefits.Business, rankBenefits.First, rankBenefits.Vip]}
            border
          />
          <div className={clsx("clear__sp", styles.borderRight)} />
          <RankBenefitItem
            rankName="First"
            rankBenefit={rankBenefits.First}
            index={1}
            qualifiedRanks={[rankBenefits.First, rankBenefits.Vip]}
            border
          />
          <RankBenefitItem
            rankName="Vip"
            rankBenefit={rankBenefits.Vip}
            index={2}
            qualifiedRanks={[rankBenefits.Vip]}
          />
        </div>
        <MileProgramFunContents />
        <UserVoices />
        <Button
          variants="gray"
          rounded
          as="a"
          href="https://basefood.zendesk.com/hc/ja/articles/900001979383-%E3%83%99%E3%83%BC%E3%82%B9%E3%83%95%E3%83%BC%E3%83%89%E3%81%AE%E3%83%9E%E3%82%A4%E3%83%AB%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0%E3%81%AF%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AA%E5%88%B6%E5%BA%A6%E3%81%A7%E3%81%99%E3%81%8B"
        >
          ランクと特典についてさらに詳しく
        </Button>
      </Column>
    </section>
  );
}
