import howtoReceive1 from "$/images/egift/HowtoReceive_01.png";
import howtoReceive2 from "$/images/egift/HowtoReceive_02.png";
import howtoReceive3 from "$/images/egift/HowtoReceive_03.png";
import howtoSend1 from "$/images/egift/HowtoSend_01.png";
import howtoSend2 from "$/images/egift/HowtoSend_02.png";
import howtoSend3 from "$/images/egift/HowtoSend_03.png";
import startset1 from "$/images/pkg/startset_1.png";
import startset2 from "$/images/pkg/startset_2.png";
import startset3 from "$/images/pkg/startset_3.png";
import startset5 from "$/images/pkg/startset_5.png";

export const egiftSections = {
  About: {
    id: "EgiftAbout",
    title: "BASE FOOD\neギフト",
  },
  Send: {
    id: "EgiftSend",
    title: "eギフトの贈り方",
  },
  Receive: {
    id: "EgiftReceive",
    title: "eギフトの受け取り方",
  },
  Lineup: {
    id: "EgiftLineup",
    title: "贈れるギフト",
  },
  Faq: {
    id: "EgiftFaq",
    title: "よくある質問",
  },
};

export const egiftSectionItems = [
  egiftSections.About,
  egiftSections.Send,
  egiftSections.Receive,
  egiftSections.Lineup,
  egiftSections.Faq,
];

export const egiftLineup = {
  set1: {
    name: "パン14袋セット",
    img: startset1.src,
    price: "¥4,206",
    deliveryfee: true,
    link: "/products/subscription/1",
    singleLine: true,
  },
  set2: {
    name: "パン10袋＆クッキー10袋セット",
    img: startset2.src,
    price: "¥5,186",
    deliveryfee: true,
    link: "/products/subscription/2",
    singleLine: false,
  },
  set3: {
    name: "パン10袋&パスタ4袋セット",
    img: startset3.src,
    price: "¥5,050",
    deliveryfee: true,
    link: "/products/subscription/3",
    singleLine: false,
  },
  set4: {
    name: "パン16袋&パンケーキミックス2袋セット",
    img: startset5.src,
    price: "¥5,624",
    deliveryfee: true,
    link: "/products/subscription/5",
    singleLine: false,
  },
};

export const egiftLineupItems = [
  egiftLineup.set1,
  egiftLineup.set2,
  egiftLineup.set3,
  egiftLineup.set4,
];

export const egiftHowToSend = {
  step1: {
    id: 1,
    title: "「eギフトで贈る」を\n選択する",
    content:
      "商品ページで「住所を知らない相手にeギフトで贈る」を選択するとeギフトをご利用いただけます。",
    img: howtoSend1.src,
  },
  step2: {
    id: 2,
    title: "メッセージを入力して\n購入する",
    content:
      "メッセージなどの必要項目を入力し、eギフトを購入します。贈り手の方は「メールアドレス」の情報のみでeギフトの購入が可能です。",
    img: howtoSend2.src,
  },
  step3: {
    id: 3,
    title: "URLをSNSやメールで\n送信する",
    content:
      "購入が完了すると、eギフトの受け取りURLが発行されます。LINEなど各SNSやメールでURLを伝えることでeギフトを贈ることができます。",
    img: howtoSend3.src,
  },
};

export const egiftHowToSendItems = [
  egiftHowToSend.step1,
  egiftHowToSend.step2,
  egiftHowToSend.step3,
];

export const egiftHowToReceive = {
  step1: {
    id: 1,
    title: "eギフトURLが届く",
    content:
      "URLをタップすると受け取り画面が表示されます。「eギフトを受け取る」 をタップしてお届け先情報の入力に進みます。",
    img: howtoReceive1.src,
  },
  step2: {
    id: 2,
    title: "お届け先を入力する",
    content: "お届け先情報を入力します。\n※入力した情報は、贈り主にはわからない仕組みです。",
    img: howtoReceive2.src,
  },
  step3: {
    id: 3,
    title: "ギフトを受け取る",
    content: "入力したお届け先に、\neギフト商品が配送されます。",
    img: howtoReceive3.src,
  },
};

export const egiftHowToReceiveItems = [
  egiftHowToReceive.step1,
  egiftHowToReceive.step2,
  egiftHowToReceive.step3,
];
