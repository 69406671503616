import clsx from "clsx";

import { CartModel, CartItemModel } from "@/models/cart/type";

import styles from "./FooterButtons.module.scss";
import { CheckoutButtons } from "../CheckoutButtons";

export interface FooterButtonsProps {
  cart: CartModel;
  cartItems: CartItemModel[];
  isLoggedIn: boolean;
}

export function FooterButtons({
  cart,
  cartItems,
  isLoggedIn,
}: FooterButtonsProps): React.ReactNode {
  return (
    <section
      id="product-fixed-section" // nuxtにあったid。どこで使用しているか不明
      className={clsx(
        "mg__top__off mg__bottom__off bg__yellow clear__pc abtesting",
        styles.container
      )}
    >
      <div className={styles.inner}>
        <div className="container">
          <div className="row">
            <div className={clsx("col-12 text__center text__left__pc", styles.buttons)}>
              <CheckoutButtons cart={cart} cartItems={cartItems} isLoggedIn={isLoggedIn} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
