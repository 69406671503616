import { useCallback } from "react";

import { isAxiosError } from "axios";

import { sendPasswordResetMail } from "@/generated/axios-functions/payseAPI";
import { ValidationError } from "@/utils/error";

import { PasswordResetMailFormSchemaValue } from "./schema";

export const useSendEmail = () => {
  const sendEmail = useCallback(async ({ email }: PasswordResetMailFormSchemaValue) => {
    try {
      await sendPasswordResetMail({ email });
    } catch (error) {
      if (isAxiosError(error) && error.status === 400) {
        throw new ValidationError("登録されていないメールアドレスです。入力内容をご確認ください。");
      }
    }
  }, []);
  return { sendEmail };
};
