import React from "react";

import { EgiftSection } from "@/components/domains/anygift/EgiftSection";

import { egiftSections, egiftHowToSendItems } from "../consts";

export function EgiftSend(): React.ReactNode {
  return (
    <EgiftSection
      title="贈り手"
      styleClass="sender"
      items={egiftHowToSendItems}
      sectionId={egiftSections.Send.id}
    />
  );
}
