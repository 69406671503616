import clsx from "clsx";

import { Image } from "@/components";

import styles from "./TargetItem.module.scss";
import { StampTarget } from "../const";

interface TargetItemProps {
  item: StampTarget;
}

export function TargetItem({ item }: TargetItemProps): React.ReactNode {
  return (
    <div className={styles.wrapper}>
      <dl className={styles.item}>
        <dt className={clsx(styles.imageContainer, item.isPurchased && styles.purchasedIcon)}>
          <Image
            src={`https://asset.basefood.co.jp/images/pkg/${item.image}.png`}
            alt={item.fullName}
            containerClassName={clsx(item.isPurchased && styles.purchasedImageContainer)}
            size={{ width: 80, height: 80 }}
          />
        </dt>

        <dd className={styles.title}>
          {item.isRegistered ? (
            <p>
              {item.title}
              <sup className="sup">
                <i className="far fa-registered"></i>
              </sup>
            </p>
          ) : (
            <p>{item.title}™︎</p>
          )}
          {item.name}
          {item.isFreeze && (
            <p>
              <span className="tag bg__darkblue square text__white inline mini mg__top__xs">
                冷凍
              </span>
            </p>
          )}
        </dd>
      </dl>
    </div>
  );
}
