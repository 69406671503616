"use client";

import { useState } from "react";

import clsx from "clsx";
import { format } from "date-fns";

import Pagination from "@/components/displays/Pagination";
import { useParsedGetCustomerPoints } from "@/queries";
import { numberFormat, withSuspense } from "@/utils";

import styles from "./PointHistory.module.scss";

function _PointHistory(): React.ReactNode {
  const [page, setPage] = useState(1);
  const { data } = useParsedGetCustomerPoints(page);
  const { points = [], meta: paginate } = data || {};
  const totalPage = paginate?.lastPage || 0;

  return (
    <div>
      {points.map((point, index) => (
        <dl key={index} className={styles.history}>
          <dt>
            <span className={clsx("text__m", styles.date)}>
              {format(point?.createdAt, "yyyy/MM/dd")}
            </span>
            <span className="text__m text__bold">{point.label}</span>
          </dt>
          <dd>
            {point?.point && point.point >= 0 ? (
              <p className="text__l text__bold">+{numberFormat(point.point)}</p>
            ) : (
              <p className="text__l text__bold">-{numberFormat(Math.abs(point.point || 0))}</p>
            )}
          </dd>
        </dl>
      ))}
      <div className="mg__top__m">
        <Pagination totalPage={totalPage} onPageChange={setPage} />
      </div>
    </div>
  );
}

export const PointHistory = withSuspense(_PointHistory, null); // NOTE: 重要でない部分なのでローディング中の表示は省略する
