"use client";
import clsx from "clsx";

import { Image } from "@/components";

import kvPc from "$/images/egift/kv_egift_pc.jpg";
import kvSp from "$/images/egift/kv_egift_sp.jpg";

import styles from "./HeaderSection.module.scss";

export function HeaderSection(): React.ReactNode {
  return (
    <section>
      <Image
        containerClassName={clsx("clear__pc", styles.kv)}
        className={clsx(styles.headerImage)}
        alt="eギフト画像"
        src={kvSp.src}
        aspectRatio="75 / 188"
      />
      <Image
        containerClassName={clsx("clear__sp")}
        className={clsx(styles.headerImage)}
        alt="eギフト画像"
        src={kvPc.src}
        aspectRatio="351 / 148"
      />
    </section>
  );
}
