"use client";

import clsx from "clsx";

import { MileRankInfo } from "@/components/domains/mypage";

import styles from "./RankSection.module.scss";

export function RankSection(): React.ReactNode {
  return (
    <section className={clsx("bg__gray", styles.container)}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mg__top__m mg__bottom__m">
              <MileRankInfo bgTransparent />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
