import { useCallback, useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { Button } from "@/components";
import { buildCheckoutUrl } from "@/features";
import { CartModel, CartItemModel } from "@/models/cart/type";

import styles from "./CheckoutButtons.module.scss";

export interface CheckoutButtonsProps {
  cart: CartModel;
  cartItems: CartItemModel[];
  isLoggedIn: boolean;
}

export function CheckoutButtons({
  cart,
  cartItems,
  isLoggedIn,
}: CheckoutButtonsProps): React.ReactNode {
  const { push } = useRouter();
  const redirectToSubscription = useCallback(() => {
    if (
      cart.freezeProducts &&
      cart.freezeProducts.length > 0 &&
      cart.normalProducts?.length === 0
    ) {
      push("/mypage/freeze_subscription?anchor=order-summary");
      return;
    }
    push("/mypage/subscription?anchor=order-summary");
  }, [cart.freezeProducts, cart.normalProducts?.length, push]);

  const canSubmit = useMemo(() => {
    const normal = cart.normalProducts?.length === 0 ? true : cart.validPurchase;
    const freeze = cart.freezeProducts?.length === 0 ? true : cart.validFreezePurchase;
    return normal && freeze;
  }, [cart]);

  const submit = useCallback(() => {
    push(buildCheckoutUrl(cartItems));
  }, [cartItems, push]);

  return (
    <div>
      {isLoggedIn && cart?.isSubscription ? (
        <Button
          variants="primary"
          rounded
          className="text__m text__bold mg__bottom__s"
          onClick={redirectToSubscription}
        >
          マイページで追加・変更
          <i className="fas fa-angle-right"></i>
        </Button>
      ) : (
        <>
          <Link href="/" className="btn gray inline round text__black text__m text__bold">
            買い物を続ける
          </Link>
          <Button
            variants="primary"
            rounded
            className={clsx("text__m text__bold", styles.button)}
            disabled={!canSubmit}
            onClick={submit}
          >
            レジに進む
          </Button>
        </>
      )}
    </div>
  );
}
