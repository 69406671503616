import { Image } from "@/components/displays";

export function SafetyBanner(): React.ReactNode {
  return (
    <section className="bg__gray pd__top__l">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-6 col-offset-m-3">
            <a href="https://basefood.co.jp/safety" target="_blank">
              <Image
                src="https://asset.basefood.co.jp/images/bnr/bnr_safety_W960_H300_2.jpg"
                alt="安心・安全の取り組み"
                aspectRatio="16/5"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
