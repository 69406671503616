"use client";

import { useCallback } from "react";

import clsx from "clsx";

import styles from "./StampBox.module.scss";

interface StampBoxProps {
  stamps?: string[];
}

const stampNumbers = Array.from({ length: 11 }, (_, i) => i + 1);
const stampPointTargets = [
  {
    position: 2,
    point: 100,
  },
  {
    position: 3,
    point: 100,
  },
  {
    position: 6,
    point: 100,
  },
  {
    position: 11,
    point: 500,
  },
];

export function StampBox({ stamps }: StampBoxProps): React.ReactNode {
  const isStamped = useCallback((num: number) => stamps && num <= stamps.length, [stamps]);

  return (
    <>
      <p className="text__m text__bold text__center">
        対象商品の購入でスタンプが貯まり、
        <br />
        スタンプ数に応じてポイントがもらえる！
      </p>

      <div className={clsx(styles.stampCard, "mg__top__l")}>
        <p className="text__m text__bold text__center mg__bottom__m">獲得スタンプ</p>
        <div className={styles.stamps}>
          {stampNumbers.map((stampNumber) => {
            const stampPointTarget = stampPointTargets.find(
              (target) => target.position === stampNumber
            );

            return (
              <div className={styles.stamp} key={stampNumber}>
                <span
                  className={clsx(
                    styles.stampCircle,
                    isStamped(stampNumber) ? styles.stampFill : ""
                  )}
                >
                  {stampNumber}
                </span>
                {stampPointTarget && (
                  <p
                    className={clsx(
                      "text__m text__bold text__center mg__top__xs text__gray__dark",
                      isStamped(stampNumber) ? "text__red__dark" : "text__gray__dark"
                    )}
                  >
                    +{stampPointTarget.point}pt
                  </p>
                )}
              </div>
            );
          })}
          <div className={styles.flexSpace}></div>
        </div>
      </div>

      <p className="text__s text__gray__dark text__center__pc mg__top__m">
        ※対象商品1種類につき1個のスタンプが付与されます。
        <br />
        ※スタンプは注文内容変更後ではなく、注文確定後に反映されます。
      </p>
    </>
  );
}
