import React from "react";

import { EgiftSection } from "@/components/domains/anygift/EgiftSection";

import { egiftSections, egiftHowToReceiveItems } from "../consts";

export function EgiftReceive(): React.ReactNode {
  return (
    <EgiftSection
      title="受け取り手"
      styleClass="receiver"
      items={egiftHowToReceiveItems}
      sectionId={egiftSections.Receive.id}
    />
  );
}
