import { z } from "zod";

export const RegistrationPasswordFormSchema = z
  .object({
    lastName: z.string().min(1, "姓を入力してください。"),
    firstName: z.string().min(1, "名を入力してください。"),
    password: z.string().min(6, "パスワードは6文字以上で入力してください。"),
    confirmPassword: z.string().min(6, "確認用パスワードは6文字以上で入力してください。"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "確認用パスワードと一致しません。お手数ですが入力し直してください。",
    path: ["confirmPassword"],
  });

export type RegistrationPasswordFormSchemaValue = z.infer<typeof RegistrationPasswordFormSchema>;
