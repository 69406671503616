"use client";

import { useEffect, useState } from "react";

import clsx from "clsx";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { IndexProduct } from "@/components/template/ProductIndex/consts";

import { ProductItem } from "../ProductItem";

interface MobileStartSetSliderProps {
  startSets: IndexProduct[];
}

export function MobileStartSetSlider({ startSets }: MobileStartSetSliderProps): React.ReactNode {
  const [mounted, setMounted] = useState(false);

  /**
   * slidesPerViewを1以上いすると、JS側での計算は必要となり、mountedした後SwiperSlideにstyleが適用され、layout shiftが発生する
   * そのため、mountedした後のstyleと似ているstyleをSwiperSlideに適用することで、layout shiftを防ぐ
   */
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="pd__bottom__m fix-container-margin">
      <Swiper
        // SwiperSlideにおいて、mountの前styleがでキャッシュされている？可能性があり、
        // mount後にステートをリフレッシュするため、keyを変更する
        key={mounted ? "mounted" : "unmounted"}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className={clsx("clear__pc")}
        spaceBetween={10}
        slidesPerView={1.2}
        centeredSlides
      >
        {startSets.map((product, index) => (
          <SwiperSlide
            key={index}
            style={mounted ? {} : { width: "84%", marginRight: 10, marginLeft: "8%" }}
          >
            <ProductItem className="col-px-fix" product={product} white key={product.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
