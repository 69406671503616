"use client";

import React, { useCallback } from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";
import { fireKarteEvent } from "@/utils";

import styles from "./SnsShareBox.module.scss";
import { fireGtag, getSocialMediaUrl, Media } from "../helper";

interface SnsShareBoxProps {
  shareUrl: string;
}

export function SnsShareBox({ shareUrl }: SnsShareBoxProps): React.ReactNode {
  const handleClick = useCallback(
    (media: Media) => {
      try {
        // 3rd partyのトラッキングが失敗してもエラーを握りつぶす
        fireKarteEvent("click_invite_share", { media });
        fireGtag(media);
      } catch {}
      window.open(getSocialMediaUrl(media, shareUrl), "_blank")?.focus();
    },
    [shareUrl]
  );

  return (
    <div className={styles.root}>
      {/* Twitter */}
      <div className={styles.box}>
        <a
          className={styles.link}
          onClick={() => {
            handleClick("twitter");
          }}
        >
          <Image
            src="https://asset.basefood.co.jp/images/parts/x-twitter.svg"
            alt="X"
            size={{ width: 42, height: 42 }}
            containerClassName={clsx(styles.x, "clear__sp")}
          />
          <Image
            src="https://asset.basefood.co.jp/images/parts/x-twitter.svg"
            alt="X"
            size={{ width: 28, height: 28 }}
            containerClassName={clsx(styles.x, "clear__pc")}
          />
          <div className={styles.label}>X</div>
        </a>
      </div>

      {/* Facebook */}
      <div className={styles.box}>
        <a
          className={styles.link}
          onClick={() => {
            handleClick("facebook");
          }}
        >
          <i className={`fab fa-facebook-square ${styles.icon} ${styles.facebook}`} />
          <br />
          <span className={styles.label}>Facebook</span>
        </a>
      </div>

      {/* Line */}
      <div className={styles.box}>
        <a
          className={styles.link}
          onClick={() => {
            handleClick("line");
          }}
        >
          <i className={`fab fa-line ${styles.icon} ${styles.line}`} />
          <br />
          <span className={styles.label}>Line</span>
        </a>
      </div>
    </div>
  );
}
