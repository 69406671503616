"use client";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "@/components";

import styles from "./BodyChangeSection.module.scss";

const users = [
  {
    image: "https://asset.basefood.co.jp/images/parts/illust_pointpack_voice_01.png",
    alt: "20代女性",
    message: "栄養バランスを考えなきゃ、という気持ちがラクになった",
    comment:
      "コロナ在宅で人生最大の体重になったのをきっかけにはじめました。トレーニングもしたことで体重がおちたので、たのしくて続けられました。栄養バランスについて考える気持ちもすごくラクになりましたね。",
  },
  {
    image: "https://asset.basefood.co.jp/images/parts/illust_pointpack_voice_02.png",
    alt: "30代男性",
    message: "ライフスタイルにあわせられるから、1年以上続いてます",
    comment:
      "仕事柄出張が多いのですが「この時期の配送はキャンセルしよう」や「BASE BREADの数を半分に減らそう」といった細かい調整がしやすいので、安心して続けることができます。",
  },
  {
    image: "https://asset.basefood.co.jp/images/parts/illust_pointpack_voice_03.png",
    alt: "20代男性",
    message: "食事が安定すると、からだに意識的になれた",
    comment:
      "仕事のいそがしさから体重が増えて、まずいなと思いベースフードをはじめました。宅配で届くから、外にわざわざ出なくていいのもラクです。食事が安定すると、体重を測ってみたりと、自分のからだに意識的になれた気もします。",
  },
  {
    image: "https://asset.basefood.co.jp/images/parts/illust_pointpack_voice_04.png",
    alt: "30代女性",
    message: "スーパー糖質オフから、ベースフードのゆる糖質制限に",
    comment:
      "極端な糖質オフ生活のときは、常に体が疲れている感覚で長くは続かなくて。ゆるく長く続けられるベースフードはいいのかなぁと。栄養もとりたいけどおいしく食べたい、そのバランスをとってくれるのがベースフードです。",
  },
] as const;

export function BodyChangeSection(): React.ReactNode {
  return (
    <section className="bg__gray pd__bottom__xl">
      <div className="container pd__top__xl">
        <div className="row">
          <div className="col-12 text__center">
            <h4 className="text__center mg__bottom__l mg__bottom__off__pc">
              <Image
                src="https://asset.basefood.co.jp/images/parts/txt_pointpack_change.png"
                alt="つづけることで、からだの変化を実感"
                size={{ width: 280, height: 88 }}
              />
            </h4>
          </div>
        </div>
      </div>

      <div className="container mg__top__s clear__sp">
        <div className="row">
          {users.map((user) => (
            <div className="col-12 col-m-6 pd__top__xl" key={user.image}>
              <div className={styles.voice}>
                <Image
                  src={user.image}
                  alt={user.alt}
                  containerClassName={styles.user}
                  size={{ width: 131, height: 120 }}
                />
                <p className="text__l text__bold mg__bottom__m">{user.message}</p>
                <p className="text__m">{user.comment}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mg__top__s clear__pc">
        <div className="row">
          <div className="col-12">
            <Swiper
              pagination={{
                clickable: true,
                bulletActiveClass: styles.paginationBullet,
              }}
              modules={[Pagination]}
              spaceBetween={20}
              slidesPerView={1}
              className="clear__pc"
            >
              {users.map((user) => (
                <SwiperSlide key={user.image} className={""}>
                  <div className={styles.voice}>
                    <Image
                      src={user.image}
                      alt={user.alt}
                      containerClassName={styles.user}
                      size={{ width: 131, height: 120 }}
                    />
                    <p className="text__l text__bold mg__bottom__m">{user.message}</p>
                    <p className="text__m">{user.comment}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
