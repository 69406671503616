import { Image } from "@/components/displays";

import styles from "./MemberVoice.module.scss";

interface MemberVoiceProps {
  src: string;
  alt: string;
  title: React.ReactNode;
  description: string;
}

export function MemberVoice({ src, alt, title, description }: MemberVoiceProps): React.ReactNode {
  return (
    <div className={styles.memberVoice}>
      <div className={styles.imageContainer}>
        <Image src={src} alt={alt} size={{ width: 145, height: 131 }} />
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
}
