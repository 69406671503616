import { useCallback } from "react";

import clsx from "clsx";
import { useRouter } from "next/navigation";

import { Button } from "@/components/inputs";
import { ProductTemperature } from "@/models/product/type";

interface ProductAdditionLinkProps {
  temperature: ProductTemperature;
  variantIds: string[];
  isFilled: boolean;
}

export function ProductAdditionLink({
  temperature,
  variantIds,
  isFilled,
}: ProductAdditionLinkProps): React.ReactNode {
  const router = useRouter();
  const onClick = useCallback(() => {
    router.push(
      `/mypage/${temperature === "normal" ? "subscription" : "freeze_subscription"}/?variant_id=${variantIds.join(",")}`
    );
  }, [router, temperature, variantIds]);

  return (
    <Button
      variants={temperature === "normal" ? "yellow" : "lightblue"}
      rounded
      disabled={isFilled}
      onClick={onClick}
      className="text__m text__bold"
    >
      <span className={clsx(temperature === "freeze" && "text__white")}>
        {temperature === "normal" ? "常温" : "冷凍"}便の新商品を追加する
      </span>
    </Button>
  );
}
