import { useCallback } from "react";

import { useSearchParams } from "next/navigation";

import { useAuth } from "@/components";
import {
  getCustomer,
  getCustomerIsRegistered,
  login as postLogin,
  loginCheckout,
  loginCommune,
  loginLineAt,
  mypage,
} from "@/generated/axios-functions/payseAPI";
import { HandleError, ValidationError } from "@/utils/error";

import { LoginFormSchemaValue } from "./schema";

interface UseLoginProps {
  isLabo?: boolean;
}

export const useLogin = ({ isLabo }: UseLoginProps) => {
  const { setAccessToken } = useAuth();
  const params = useSearchParams();
  const redirectPath = params.get("redirect_path") || "";

  const execLogin = useCallback(
    ({ email, password }: LoginFormSchemaValue) => {
      if (isLabo || params.get("site") === "commune") {
        return loginCommune({ email, password });
      } else if (redirectPath) {
        return postLogin({ email, password });
      } else if (params.get("redirect_checkout")) {
        return loginCheckout({ email, password, url: "/checkout" });
      } else if (params.get("redirect_lineat")) {
        const nonce = params.get("nonce")!;
        const linkToken = params.get("linkToken")!;
        return loginLineAt({ email, password, nonce, linkToken });
      } else {
        return mypage({ email, password });
      }
    },
    [isLabo, params, redirectPath]
  );

  const login = useCallback(
    async ({ email, password }: LoginFormSchemaValue) => {
      const isRegisteredResponse = await getCustomerIsRegistered({ email });
      if (!isRegisteredResponse?.is_password_registered) {
        const message = isRegisteredResponse?.messages?.join("\n") || "不明なエラーが発生しました";
        throw new ValidationError(message);
      }

      const loginResponse = await execLogin({ email, password });
      const token = loginResponse?.token;
      if (!token) {
        throw new HandleError();
      }

      setAccessToken(token);
      const customerResponse = await getCustomer();
      const customer = customerResponse?.customer;
      if (!customer) {
        throw new HandleError();
      }

      return `${loginResponse.url}${redirectPath}` || "/";
    },
    [execLogin, redirectPath, setAccessToken]
  );

  return { login };
};
