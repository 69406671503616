import { useCallback, useEffect, useState } from "react";

export const useHandleScroll = (ref: React.RefObject<HTMLElement>) => {
  const [isScrollLimit, setIsScrollLimit] = useState(true);

  const checkScrollLimit = useCallback(() => {
    const elem = ref?.current;
    if (elem) {
      const height = elem.offsetTop + 36;
      if (window.scrollY > height) {
        setIsScrollLimit(false);
      } else {
        setIsScrollLimit(true);
      }
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollLimit);

    return () => {
      window.removeEventListener("scroll", checkScrollLimit);
    };
  }, [checkScrollLimit]);

  return {
    isScrollLimit,
  };
};
