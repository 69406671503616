"use client";

import React from "react";

import clsx from "clsx";

import { AnygiftAnchorLink } from "@/components/domains/anygift/AnygiftAnchorLink/AnygiftAnchorLink";

import styles from "./EgiftAgenda.module.scss";
import { egiftSectionItems } from "../consts";

export function EgiftAgenda(): React.ReactNode {
  return (
    <section className="bg__yellow clear__pc">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-6 col-offset-m-3">
            <div className={clsx(styles.block, "bg__white")}>
              <p className="text__xl text__bold text__center mg__bottom__m">目次</p>
              <nav>
                <ul>
                  {egiftSectionItems.map((section) => (
                    <li key={section.id} className={styles.list}>
                      <AnygiftAnchorLink key={section.id} to={section.id}>
                        {section.title}
                      </AnygiftAnchorLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
