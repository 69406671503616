"use client";
import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { usePostParsedCartFromCartItems } from "@/queries";
import { withCsr } from "@/utils";
import { sumBy } from "@/utils/array";
import { formatCurrency } from "@/utils/currency";

import styles from "./ProductSummaryPanel.module.scss";

export const ProductSummaryPanel = withCsr(function ProductSummaryPanel(): React.ReactNode {
  const { data: cart } = usePostParsedCartFromCartItems();
  const totalPriceWithoutShippingFee = (cart?.totalPrice ?? 0) - (cart?.totalShippingFee ?? 0);
  const totalProductCount = useMemo(() => sumBy(cart?.products ?? [], "quantity"), [cart]);
  if (totalProductCount === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className="text__left">
        <p className="text__bold">
          <span className={clsx("bg__white", "text__m", styles.count)}>{totalProductCount}</span>
          <span className={clsx("text__l", styles.price)}>
            {formatCurrency(totalPriceWithoutShippingFee)}
          </span>
        </p>
      </div>

      <Link className={clsx("text__center", styles.link)} href="/cart">
        <span className={clsx("text__m", "text__bold")}>カートを確認</span>
      </Link>
    </div>
  );
});
