import clsx from "clsx";

import { PriceProgressBar, TotalPrice } from "@/components";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { CartModel } from "@/models/cart/type";

import styles from "./CartHeader.module.scss";

export interface CartHeaderProps {
  cart: CartModel;
  isScrollLimit: boolean;
}

export function CartHeader({ cart, isScrollLimit }: CartHeaderProps): React.ReactNode {
  const normalPrice = cart.normalProductTotalPrice ?? 0;
  const freezePrice = cart.freezeProductTotalPrice ?? 0;

  return (
    <div className="clear__pc">
      <div className={styles.dummyHeight} />
      <div className={clsx(styles.container, !isScrollLimit && styles.fixedContainer)}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p
                className={clsx(
                  "text__xl text__bold",
                  styles.title,
                  !isScrollLimit && styles.hideTitle
                )}
              >
                <span>ショッピングカート</span>
              </p>
              <div className={clsx("text__right", styles.totalPrice)}>
                <span className="tag border__bk square mini inline">
                  {cart.isSubscription ? "継続コース" : "1回のみお届け"}
                </span>
                <span className="text__m text__bold">合計：</span>
                <TotalPrice cart={cart} className="text__xl" />
              </div>
              {cart.normalProducts && cart.normalProducts.length > 0 && (
                <PriceProgressBar
                  price={normalPrice}
                  max={PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal}
                  colorClass={"bg__yellow"}
                  title={"常温配送"}
                />
              )}
              {cart.freezeProducts && cart.freezeProducts.length > 0 && (
                <PriceProgressBar
                  price={freezePrice}
                  max={PRODUCT_SALE_PARAMETER.minDeliveryAmountFrozen}
                  colorClass={"bg__darkblue"}
                  title={"冷凍配送"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
