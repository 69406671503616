export const EgiftFaqData = {
  usageConditions: {
    title: "eギフトの利用条件",
    items: [
      {
        question: "すべての商品をeギフトで贈れますか？",
        answer:
          "各商品ページに「住所を知らない相手にeギフトで贈る」というボタンが表示されているもののみ可能です。",
      },
      {
        question: "通常の商品とeギフト対象の商品を同時に購入できますか？",
        answer: "同時購入はできません。また同梱発送もできませんのでご注意ください。",
      },
      {
        question: "複数の商品をeギフトで贈ることはできますか？",
        answer:
          "商品ページに「住所を知らない相手にeギフトで贈る」ボタンが表示されている商品同士であれば、同時購入が可能です。",
      },
      {
        question: "相手の本名がわからなくてもギフトを贈れますか？",
        answer: "お相手の本名がわからなくても大丈夫です。",
      },
      {
        question: "海外在住の方にも贈れますか？",
        answer: "商品のお届けは日本国内に限らせていただきます。",
      },
    ],
  },
  paymentAndOrder: {
    title: "支払い・注文関連",
    items: [
      {
        question: "お支払い方法はなにが使えますか？",
        answer: "クレジットカード決済のみご利用可能です。",
      },
      {
        question: "会員登録していますがポイントは使用できますか？",
        answer:
          "恐れ入りますがシステムの関係上「eギフト」でのご注文の場合は、ポイントをご利用いただけません。またポイント付与もございません。ご了承お願い申し上げます。",
      },
      {
        question: "注文完了後、商品変更、注文キャンセルをすることはできますか？",
        answer:
          "ご注文手続きが完了した後のお客様都合によるご注文内容の変更・追加およびキャンセルはお受け致しかねます。",
      },
      {
        question: "注文完了後に名前やメッセージの入力を変更できますか？",
        answer: "変更することはできませんので、ご注意ください。",
      },
    ],
  },
  giftDelivery: {
    title: "eギフトの送付・確認・有効期限",
    items: [
      {
        question: "eギフトURLはどのように送られますか？",
        answer:
          "購入完了後に、購入完了画面に表示されます。また、通常の注文完了メールとは別のメールで、eギフトのURLが載ったメールが送られます。メールを介して専用URLをお送りする仕組みとなっているため「@(貴社のドメイン名）」からのメールを受信できるようドメイン指定受信設定をお願いいたします。",
      },
      {
        question: "有効期限はありますか？",
        answer:
          "購入した日から5日後の23:59が有効期限です。それまでに贈り先様へURLを送付するだけでなく、そのURLからお届け先情報をご入力いただくようご連絡お願いいたします。",
      },
      {
        question: "eギフトを贈った相手が住所を入力したかどうかを確認できますか？",
        answer:
          "お相手の方が住所入力を完了しましたら、ご注文者様へメールが届きます。その後の配送ご案内等は贈り先様にのみご連絡いたします。",
      },
      {
        question: "受け取り相手が住所を入力しなかった場合はどうなりますか？",
        answer:
          "ご購入商品はキャンセルとならず「ご注文者様」の住所にお届けとなります。メールにて確認のご連絡を差し上げます。お届け希望日時の指定はできませんのでご了承ください。なお長期ご不在・住所不明・受取辞退などにより商品が弊社に返品となった場合でも、ご返金は承れません。",
      },
    ],
  },
};
