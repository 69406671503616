"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";

import { PointBox, SubscriptionTogglePanel } from "@/components";
import { Image } from "@/components/displays";
import { FEATURE_FLAG } from "@/configs/system";
import { CartModel } from "@/models/cart/type";

import styles from "./SubscriptionSection.module.scss";

export interface SubscriptionSectionProps {
  cart: CartModel;
  subscriptionEnabled: boolean;
  setSubscriptionEnabled: (value: boolean) => void;
}

export function SubscriptionSection({
  cart,
  subscriptionEnabled,
  setSubscriptionEnabled,
}: SubscriptionSectionProps): React.ReactNode {
  const [showTogglePanel, setShowTogglePanel] = useState(true);
  const switchTogglePanel = useCallback(() => {
    setShowTogglePanel((prev) => !prev);
  }, []);

  const displayExtraGiftCampaign = FEATURE_FLAG.isExtraGiftCampaignActive && cart.isSubscription;

  return (
    <>
      <div className="mg__bottom__m">
        <PointBox />
      </div>
      {displayExtraGiftCampaign && (
        <Image
          src="https://asset.basefood.co.jp/images/campaign/extraGift/bnr_W1200_H380.png"
          alt="おまけ増量キャンペーン 今なら3袋に増量中"
          aspectRatio="1200 / 380"
          containerClassName="mg__top__s mg__bottom__s"
        />
      )}
      <p className={clsx("text__m mg__bottom__m", styles.switch)}>
        購入方法：
        <span className="text__bold">{cart.isSubscription ? "継続コース" : "1回のみお届け"}</span>
        {!cart.hasNoSubscriptionProduct && (
          <span
            className="btn inline gray mini angle__right show__toggle"
            onClick={switchTogglePanel}
          >
            {showTogglePanel ? "非表示" : "コース変更"}
            <i className={clsx("fas", showTogglePanel ? "fa-angle-up" : "fa-angle-down")}></i>
          </span>
        )}
      </p>
      {!cart?.hasNoSubscriptionProduct && (
        <div className={styles.slide}>
          <div className={showTogglePanel ? styles.togglePanel : styles.togglePanelUp}>
            <SubscriptionTogglePanel
              enabled={subscriptionEnabled}
              onChange={setSubscriptionEnabled}
              firstGapPrice={cart.lineTotalPrice - cart.ifChangeSubscriptionFirstLineTotalPrice}
              afterSecondGapPrice={
                cart.lineTotalPrice - cart.ifChangeSubscriptionAfterSecondLineTotalPrice
              }
            />
          </div>
        </div>
      )}
    </>
  );
}
