"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { scroller } from "react-scroll";
import { FadeLoader } from "react-spinners";

import {
  AmazonPayPanel,
  Button,
  htmlToast,
  LoadingOverlay,
  OrderSummary,
  Row,
  useAmazonPay,
} from "@/components";
import { useAuth } from "@/components/domains/auth";
import { CheckoutProgressBar } from "@/components/domains/checkout/CheckoutProgressBar/CheckoutProgressBar";
import {
  CheckoutForm,
  convertFormValuesToStorage,
  useFormValues,
} from "@/components/domains/checkout-form";
import { getCustomerIsRegistered } from "@/generated/axios-functions/payseAPI";
import { useGetCartValidDates, useSaveCheckoutProgress } from "@/generated/open-api/cart/cart";
import { CartModel } from "@/models/cart/type";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { useParsedGetPoints, usePostParsedCart } from "@/queries";
import {
  useClientCartCart,
  useClientAmazonPay,
  useClientCartForm,
  useClientCustomerCustomer,
} from "@/storage";
import { withCsr } from "@/utils";
import {
  fireAmazonPayAction,
  getFormDataForAmazonPay,
  setFormDataForAmazonPay,
  getAmazonCheckoutUrl,
} from "@/utils/amazon";
import { theme } from "@/utils/color";
import { getErrorMessages } from "@/utils/error";
import { useCount, useIsAppWebView, useOnce } from "@/utils/hooks";
import { objectMerge } from "@/utils/object";

import styles from "./Checkout.module.scss";
import { useCartParamsState, useConfirm, useFormPercentage } from "./helpers";

const formContainerId = "one_page_checkout_form";

type InnerProps = {
  cart: CartModel;
} & ReturnType<typeof useCartParamsState>;

function InnerCheckout(props: InnerProps): React.ReactNode {
  const { cart, setCouponCode, setPoints, cartParams } = props;
  const { setForm } = useClientCartForm();
  const { setClientCartCart } = useClientCartCart();

  useEffect(() => {
    // Nuxtで利用するためにCartをセット
    setClientCartCart(cart);

    /**
     * localStorageのcart.cart.productsをcart.form.productsに同期するための処理
     *
     * AmazonPay、ログインボタンによって画面が遷移する場合、URL中のcart情報が飛んでいくため、
     * 戻ってきた際にURL中のcart情報が消えて、注文内容も消えてしまう。
     *
     * 上の問題を解消するために、その情報を即時localStorageのcart.formへ反映して記憶させる。
     * 戻ってくる際に、URL中にcart情報がなかったら、localStorageのcart.formのproductsから注文情報を復元する。
     */
    setForm((prev) =>
      objectMerge(prev, {
        products: cart.products?.map(convertProductToNuxtStoreProduct) ?? [],
      })
    );
  }, [cart, setClientCartCart, setForm]);

  const { customer } = useClientCustomerCustomer();
  const { isLoggedIn } = useAuth();
  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;

  const { form } = useClientCartForm();

  const scrollToForm = useCallback(() => {
    scroller.scrollTo(formContainerId, {
      duration: 500,
      smooth: true,
    });
  }, []);

  // AmazonPayの制御
  const amazonPayData = useAmazonPay();
  const {
    amazonPayCheckoutSessionId,
    amazonPayCheckoutData,
    amazonPayCheckoutError,
    amazonPayEnable,
  } = amazonPayData;

  const { setAmazonPayData } = useClientAmazonPay();
  useOnce(() => {
    setAmazonPayData((prev) => ({
      ...prev,
      // confirm画面で使うため保存しておく
      amazonPayEnable: amazonPayEnable,
      amazonPayCheckoutSessionId: amazonPayCheckoutSessionId,
    }));
  });
  // AmazonPayのチェックアウトデータの取得でエラーが発生した場合の処理
  useOnce(async () => {
    if (amazonPayCheckoutError) {
      alert(amazonPayCheckoutError);
      await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
      return;
    }
  });

  const updateCheckoutUrl = useCallback(
    async (cart: CartModel) => {
      if (!amazonPayCheckoutSessionId || !amazonPayEnable) return;
      const { url: checkoutUrl } = await getAmazonCheckoutUrl({
        amazonCheckoutSessionId: amazonPayCheckoutSessionId,
        amount: String(cart.totalPrice),
        is_subscription: cart.isSubscription ?? false,
        review_return_path: "/checkout/",
        result_return_path: "/checkout/confirm",
      });
      if (checkoutUrl) {
        setAmazonPayData((prev) => ({
          ...prev,
          amazonPayCheckoutUrl: checkoutUrl,
        }));
      }
    },
    [amazonPayCheckoutSessionId, amazonPayEnable, setAmazonPayData]
  );
  useOnce(() => {
    if (cart) updateCheckoutUrl(cart);
  }, amazonPayCheckoutSessionId);

  const router = useRouter();

  // AmazonPayと同一のメールアドレスのアカウントがあるかどうか
  const [hasSameEmailAccount, setHasSameEmailAccount] = useState(false);
  useOnce(async () => {
    if (amazonPayEnable) {
      const registeredRes = await getCustomerIsRegistered({
        email: amazonPayCheckoutData.email!,
      });
      setHasSameEmailAccount(registeredRes.result === "ok");
    }
  });

  const redirectCart = useCallback(
    (message?: string) => {
      message && alert(message);
      router.push("/cart");
    },
    [router]
  );

  const hasNormalProducts = (cart.normalProducts?.length ?? 0) > 0;
  const hasFreezedProducts = (cart.freezeProducts?.length ?? 0) > 0;

  // 初回のカートのバリデーション
  useOnce(() => {
    if (!cart || !cart.products?.length) {
      redirectCart();
      return;
    }
    if (
      // 通常商品、冷凍商品がある場合は、各々のバリデーション結果が有効であること
      (hasNormalProducts && !cart.validPurchase) ||
      (hasFreezedProducts && !cart.validFreezePurchase)
    ) {
      // 2600円未満でcheckout画面に到達するとここにくる
      redirectCart();
    }
  });

  // フォームの状態管理
  const [formValues, setFormValues] = useFormValues({
    localStorageForm: form,
    sessionStorageForm: getFormDataForAmazonPay("amazon_pay_input_data"),
    customer,
    amazonPayData,
    hasBothProducts: hasNormalProducts && hasFreezedProducts,
  });

  const { mutateAsync: validateForm } = useSaveCheckoutProgress();
  const handleChangedEmail = useCallback(
    async (email: string) => {
      if (email) {
        try {
          await validateForm({
            data: { email, products: cart.products.map(convertProductToNuxtStoreProduct) },
          });
        } catch (e) {
          htmlToast.error(getErrorMessages(e));
        }
      }
    },
    [cart.products, validateForm]
  );

  const isPaymentMethodUnnecessary = useMemo(() => {
    return (
      isLoggedIn &&
      cart &&
      cart.usePointType === "all" &&
      cart.totalPrice === 0 &&
      !cart.isSubscription
    );
  }, [cart, isLoggedIn]);

  const handleClickAmazonPay = useCallback(() => {
    setFormDataForAmazonPay("amazon_pay_input_data", convertFormValuesToStorage(formValues));
  }, [formValues]);

  // 未入力項目の計算
  const { percentage: formPercentage, unfilledCount: formUnfilledCount } = useFormPercentage({
    formValues,
    isPaymentMethodUnnecessary,
    isLoggedIn,
  });

  const [isFormHidden, setIsFormHidden] = useState(() => {
    // 半分以上入力済みの場合はフォームを表示する
    if (amazonPayEnable || isLoggedIn || formPercentage >= 50) {
      return false;
    }
    return true;
  });

  // ------ ポイント利用 ------
  // 現在保持しているポイント
  const { data } = useParsedGetPoints({ enabled: isLoggedIn });
  const currentPoints = data?.total ?? 0;
  const isPointsOnlyPayment =
    cartParams.usePointType === "all" &&
    // ポイントがなくなったら継続コースの決済に失敗することを防ぐために、継続コースの場合は支払い方法の登録が必須となる
    !cart.isSubscription &&
    // totalPriceWithNoPointDiscountに代引き手数料も含まれているため、除外しなければいけない
    currentPoints >= cart.totalPriceWithNoPointDiscount - cart.totalPriceCashDeliveryFee;

  useOnce(() => {
    if (currentPoints > 0 && cart.paidTotalPoint > 0) {
      // 購入したポイントがある場合は、そのポイントをデフォルトで利用する
      // ポイント利用の初期値がCart.paidTotalPointに依存、Cartがポイント利用に依存しており、循環しているため、ここに初期化処理を記述
      setPoints(currentPoints);
    }
  });

  const confirm = useConfirm();
  const handleClickConfirm = useCallback(async () => {
    try {
      startLoading();
      await confirm({
        cart,
        formValues,
        isLoggedIn,
        isPaymentMethodUnnecessary,
        hasSameEmailAccount,
        amazonPayEnable,
        amazonPayCheckoutSessionId,
      });
      router.push("/checkout/confirm");
    } catch (e) {
      htmlToast.error(getErrorMessages(e));
      scrollToForm();
    } finally {
      endLoading();
    }
  }, [
    startLoading,
    confirm,
    cart,
    formValues,
    isLoggedIn,
    isPaymentMethodUnnecessary,
    hasSameEmailAccount,
    amazonPayEnable,
    amazonPayCheckoutSessionId,
    router,
    scrollToForm,
    endLoading,
  ]);

  const isAppWebView = useIsAppWebView();

  const { data: datesOptionsRes } = useGetCartValidDates();
  const deliveryDateOptions = useMemo(() => {
    let options =
      datesOptionsRes?.valid_dates?.map((validDate) => {
        // valueにはyyyy/MM/ddの形式で値が入っている
        return {
          value: validDate.value!,
          label: validDate.label!,
        };
      }) ?? [];
    const includesOutlet = cart?.products.some((product) => product.isOutlet);
    if (includesOutlet) {
      // Outletが含まれる場合は、配達日の先頭の7日間のみ表示
      const limitDates = 7;
      options = options.slice(0, limitDates);
    }
    return options;
  }, [cart?.products, datesOptionsRes?.valid_dates]);

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div id={formContainerId}>
        <div className="container">
          <main className={clsx("row", styles.reverse)}>
            <OrderSummary
              cart={cart}
              currentPoints={currentPoints}
              onChangeCoupon={setCouponCode}
              onChangePoint={setPoints}
            />
            <div className={clsx("col-12", "col-m-7")}>
              <p className={clsx("text__l", "text__bold")}>ご注文方法</p>
              {!amazonPayEnable && (
                <AmazonPayPanel
                  onClickPayButton={handleClickAmazonPay}
                  isSubscription={cart?.isSubscription ?? false}
                  reviewReturnPath="/checkout"
                  resultReturnPath="/checkout/confirm"
                />
              )}
              <CheckoutForm
                cart={cart}
                isLoggedIn={isLoggedIn}
                defaultValues={formValues}
                onChange={setFormValues}
                showBackButton={!isAppWebView}
                showLogin={true}
                showCoupon={false}
                hiddenForm={isFormHidden}
                confirmButtonVariants="blue"
                isSubmittable={formUnfilledCount === 0}
                onConfirm={handleClickConfirm}
                onChangedEmail={handleChangedEmail}
                hidePaymentSection={isPointsOnlyPayment}
                deliveryDateOptions={deliveryDateOptions}
              />
              {isFormHidden && (
                <div className={clsx("text__center", styles.hideForm)}>
                  <Button variants="gray" arrowRight rounded onClick={() => setIsFormHidden(false)}>
                    フォームを入力する
                    <i className={clsx("fas", "fa-angle-down")} />
                  </Button>
                </div>
              )}
            </div>
          </main>
        </div>
        {!amazonPayEnable && (
          <div className={styles.processFixed}>
            <CheckoutProgressBar
              percentage={formPercentage}
              unfilledCount={formUnfilledCount}
              onClickConfirm={handleClickConfirm}
            />
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
}

export const Checkout = withCsr(() => {
  const { cartParams, setCouponCode, setPoints } = useCartParamsState();
  const { data: cart } = usePostParsedCart(cartParams, {
    placeholderData: keepPreviousData,
  });

  if (!cart) {
    return (
      <Row className={styles.loaderContainer}>
        <FadeLoader color={theme.yellow} loading />
      </Row>
    );
  }
  return (
    <InnerCheckout
      cart={cart}
      setCouponCode={setCouponCode}
      setPoints={setPoints}
      cartParams={cartParams}
    />
  );
});
