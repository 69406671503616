export const mileProgramSections = {
  About: {
    id: "MileProgramAbout",
    title: "マイルプログラムとは？",
  },
  Gift: {
    id: "MileProgramGift",
    title: "交換できるギフト",
  },
  Benefit: {
    id: "MileProgramBenefit",
    title: "ランク特典",
  },
  Earn: {
    id: "MileProgramEarn",
    title: "マイルを増やす方法",
  },
};

export const mileProgramSectionItems = [
  mileProgramSections.About,
  mileProgramSections.Gift,
  mileProgramSections.Benefit,
  mileProgramSections.Earn,
];
