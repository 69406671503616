"use client";
import { useCallback, useState } from "react";
import React from "react";

import clsx from "clsx";

import styles from "./EgiftFaq.module.scss";
import { egiftSections } from "../consts";
import { EgiftFaqData } from "./consts";

export function EgiftFaq() {
  const [openIndex, setOpenIndex] = useState<{ [key: string]: boolean }>({});

  const handleToggle = useCallback((categoryIndex: number, itemIndex: number) => {
    const key = `${categoryIndex}-${itemIndex}`; // 識別キーを生成
    setOpenIndex((prevOpenIndex) => ({
      ...prevOpenIndex,
      [key]: !prevOpenIndex[key], // 個別の項目の開閉状態をトグル
    }));
  }, []);

  return (
    <section id={egiftSections.Faq.id} className="bg__gray pd__top__xl">
      <div className="container">
        <div className="row">
          <div className="col-12 pd__bottom__xl">
            <p className="text__xxl text__bold text__center">{egiftSections.Faq.title}</p>
          </div>
          {Object.values(EgiftFaqData).map((section, categoryIndex) => (
            <div key={categoryIndex} className="col-12 col-m-4 pd__bottom__xl">
              <p
                className={clsx(
                  styles.faqSectionTitle,
                  "text__l text__bold text__center bg__white mg__bottom__l"
                )}
              >
                {section.title}
              </p>
              {section.items.map((item, itemIndex) => {
                const key = `${categoryIndex}-${itemIndex}`; // 識別キーを再生成
                const isOpen = openIndex[key];
                return (
                  <dl key={itemIndex} className={styles.faqSet}>
                    <dt
                      className={clsx(
                        styles.faqQuestion,
                        isOpen && styles.open,
                        "pd__top__m pd__bottom__m"
                      )}
                      onClick={() => handleToggle(categoryIndex, itemIndex)}
                    >
                      <p className="text__m text__bold">{item.question}</p>
                    </dt>
                    <dd className={clsx(styles.faqAnswer, isOpen && styles.open)}>
                      <p className="text__m">{item.answer}</p>
                    </dd>
                  </dl>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
