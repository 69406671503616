"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";

import styles from "./QuestionSection.module.scss";

const questions = [
  {
    title: "ポイントはどの商品につかえる？",
    content:
      "ベースフード公式HPの全商品（継続コース/1回のみお届け）および送料にご利用いただけます。",
  },
  {
    title: "途中で解約やお休みはできる？",
    content:
      "継続コースを解約しても、有効期限内であればポイントは保持されます。解約をしなくても、お届け予定日を後ろ倒しすることでお休みすることもできます。",
  },
  {
    title: "友だち紹介ポイントも合算される？",
    content:
      "友だち紹介ポイントも合算されます。有効期限はともに、ポイントを最後に獲得した日から2年間です。",
  },
  {
    title: "ポイントをつかいきれるか心配",
    content:
      "ポイントの有効期限は2年間なので、ライフスタイルにあわせてポイントをつかいきることができます。",
  },
  {
    title: "何ポイントまで保有できるの？",
    content: "合計100,000ポイントまで保有できます。",
  },
];

export function QuestionSection(): React.ReactNode {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleClickFaq = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        const newValue = openIndexes.filter((current) => current != index);
        setOpenIndexes(newValue);
      } else {
        setOpenIndexes([...openIndexes, index]);
      }
    },
    [openIndexes]
  );

  return (
    <div>
      <section className="bg__white">
        <div className="container pd__top__xl pd__bottom__xl">
          <div className="row">
            <div className="col-12 col-m-10 col-offset-m-1 text__center">
              <h2 className="text__xl text__bold text__center mg__bottom__l">よくある質問</h2>
              <div className={clsx("text__left", styles.faqs)}>
                {questions.map((question, index) => (
                  <dl key={index}>
                    <dt
                      className={clsx("text__m text__bold", styles.faqItem)}
                      onClick={() => handleClickFaq(index)}
                    >
                      {question.title}
                      <i
                        className={clsx(
                          "fas",
                          styles.faqIcon,
                          openIndexes.includes(index) ? "fa-times" : "fa-plus"
                        )}
                      ></i>
                    </dt>
                    {openIndexes.includes(index) && (
                      <dd className={clsx("text__m", styles.faqContent)}>{question.content}</dd>
                    )}
                  </dl>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
