"use client";

import { useMemo, useState } from "react";

import { useSearchParams } from "next/navigation";

import { FreeShippingCampaignInvite } from "@/components/domains/campaign";
import { fetchInviteUrl } from "@/components/template/EcInvite/helper";
import { FEATURE_FLAG } from "@/configs/system";
import { useAsync } from "@/utils/hooks";
import { sendError } from "@/utils/sentry";

import { CopyShareUrlButton } from "./CopyShareUrlButton";
import { DoctorSection } from "./DoctorSection";
import { InviteHeaderSection } from "./InviteHeaderSection";
import { JustNowShareSection } from "./JustNowShareSection";
import { MemberVoiceSection } from "./MemberVoiceSection";
import { ReasonSection } from "./ReasonSection";
import { SafetyBanner } from "./SafetyBanner";
import { ShareSection } from "./ShareSection";

// lpのInviteコンポーネントと区別するためECをつけた
export function EcInvite(): React.ReactNode {
  const params = useSearchParams();
  const [baseUrl, setBaseUrl] = useState<string>("");

  const shareUrl = useMemo(
    () =>
      `${baseUrl}&utm_medium=official-site&utm_source=mypage&utm_campaign=invite-friends_lp_url&argument=WTgLzY2D&dmai=a62a8372013e68`,
    [baseUrl]
  );
  const qrUrl = useMemo(
    () =>
      `${baseUrl}&utm_medium=official-site&utm_source=mypage&utm_campaign=invite-friends_lp_qr&argument=WTgLzY2D&dmai=a62a836c92cb9a`,
    [baseUrl]
  );

  useAsync(async () => {
    try {
      const url = await fetchInviteUrl();
      setBaseUrl(url);
    } catch (e) {
      sendError(e);
      return;
    }
  }, []);

  return (
    <>
      <InviteHeaderSection />
      {FEATURE_FLAG.isFreeShippingCampaignActive && <FreeShippingCampaignInvite />}
      <ShareSection shareUrl={shareUrl} />
      <SafetyBanner />
      {params.has("patterna") && <MemberVoiceSection />}
      {params.has("patternb") && <ReasonSection />}
      {params.has("patternb") && <DoctorSection />}
      <JustNowShareSection shareUrl={shareUrl} qrUrl={qrUrl} />
      <CopyShareUrlButton />
    </>
  );
}
