import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./ReasonSection.module.scss";

export function ReasonSection(): React.ReactNode {
  return (
    <section className="pd__top__xl pd__bottom__xl bg__yellow">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <p className="text__bold text__xl text__center mg__bottom__l">
              BASE FOOD
              <sup className="sup">
                <i className="far fa-registered fa-fw" />
              </sup>
              が<span className="wsnr">支持されている</span>
              <br />
              <span className="text__xxl">3つの理由</span>
            </p>

            {/* 01 */}
            <div className={clsx("bg__white", styles.reason)}>
              <p className={clsx("text__xxxl text__bold mg__bottom__s", styles.markpen)}>01</p>
              <p className="text__xl text__bold mg__bottom__l">
                主食を置き換えるだけで、栄養バランスがとれる
              </p>
              <div className="text__center mg__bottom__l">
                <Image
                  src="https://asset.basefood.co.jp/lp/form/0911_compare.png"
                  alt="主食を置き換えるだけで、栄養バランスがとれる"
                  containerClassName={clsx("clear__sp", styles.image, styles.reason__image)}
                />
                <Image
                  src="https://asset.basefood.co.jp/lp/form/0911_compare_sp.png"
                  alt="主食を置き換えるだけで、栄養バランスがとれる"
                  containerClassName={clsx("clear__pc", styles.image, styles.reason__image)}
                  aspectRatio="10/19"
                />
              </div>

              <p className="text__s text__gray__dark">
                ※1 コンビニで揃えた食事は、サラダ、サンドイッチ、野菜ジュース、ヨーグルトの場合
                <br />
                ※2 継続コース初回20%OFF価格。2回目以降は10%OFF価格。
                <br />※
                グラフは、栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合。
              </p>
            </div>

            {/* 02 */}
            <div className={clsx("bg__white mg__top__xl", styles.reason)}>
              <p className={clsx("text__xxxl text__bold mg__bottom__s", styles.markpen)}>02</p>
              <p className="text__xl text__bold mg__bottom__l">
                調理の手間なしで、さっと食べられる
              </p>
              <div className="text__center">
                <p className="text__l text__bold">
                  Q 自炊にかける時間は以前と比べて短くなりましたか？
                </p>
                <Image
                  src="https://asset.basefood.co.jp/images/parts/graph_time_cooking.png"
                  alt="調理の手間なしで、さっと食べられる"
                  containerClassName={clsx(styles.image, styles.graph__image)}
                />
              </div>
            </div>

            {/* 03 */}
            <div className={clsx("bg__white mg__top__xl", styles.reason)}>
              <p className={clsx("text__xxxl text__bold mg__bottom__s", styles.markpen)}>03</p>
              <p className="text__xl text__bold mg__bottom__l">全粒粉ベースの良質な糖質</p>
              <Image
                src="https://asset.basefood.co.jp/images/ph/webp/bg_ingredients_layout.webp"
                alt="原材料のこだわり"
                containerClassName={styles.image}
                aspectRatio="49/27"
              />
              <p className="text__m mg__top__m">
                小麦全粒粉がベースのため適度に糖質を抑えられます。全粒粉、大豆、昆布、チアシードなど、
                <span className="text__bold">10種類以上の栄養豊富な食材</span>
                をブレンドすることで、おいしさも栄養もかなえました。
                <span className="text__bold">合成保存料、合成着色料不使用</span>
                。余計な添加物をつかっていないので、毎日食べても安心です。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
