"use client";

import { useState } from "react";

import { useSearchParams } from "next/navigation";

import {
  ContactlessDelivery,
  Invite,
  MileProgram,
  Point,
  Purchase,
  TermsAccordion,
} from "@/components/domains";
import { withSuspense } from "@/utils";

type AccordionItem = {
  title: string;
  Component: React.ReactNode;
}[];

const accordions: AccordionItem = [
  {
    title: "商品購入に関する利用規約",
    Component: <Purchase />,
  },
  {
    title: "友だち紹介制度に関する利用規約",
    Component: <Invite />,
  },
  {
    title: "マイルプログラム利用規約",
    Component: <MileProgram />,
  },
  {
    title: "ポイント利用規約",
    Component: <Point />,
  },
  {
    title: "置き配利用に関する規約",
    Component: <ContactlessDelivery />,
  },
];

const isAccordionId = (value: number) => !isNaN(value) && value >= 1 && value <= accordions.length;

export const Terms = withSuspense(function Terms() {
  const params = useSearchParams();
  const [accordionNumber, setAccordionNumber] = useState(() => {
    const tab = Number(params.get("show_tab"));
    return isAccordionId(tab) ? tab : 1;
  });

  return (
    <div className="mg__bottom__xl pd__top__off__pc pd__top__xl">
      <h1 className="text__xl text__bold mg__bottom__m">利用規約</h1>
      {accordions.map(({ title, Component }, index) => (
        <TermsAccordion
          title={title}
          show={accordionNumber === index + 1}
          onClick={() => setAccordionNumber(index + 1)}
          key={index}
        >
          {Component}
        </TermsAccordion>
      ))}
    </div>
  );
});
