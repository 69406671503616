import { z } from "zod";

export const RegistrationFormSchema = z.object({
  email: z.string().email("メールアドレスには正しい形式のメールアドレスを指定してください。"),
  hasAcceptedTerms: z.boolean().refine((value) => value, {
    message: "購入には利用規約への同意が必須です。",
  }),
  recaptchaToken: z.string().min(1, "reCAPTCHAの認証が必要です。"),
});

export type RegistrationFormSchemaValue = z.infer<typeof RegistrationFormSchema>;
