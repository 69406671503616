"use client";

import { useCallback } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { isAxiosError } from "axios";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { Controller, useForm } from "react-hook-form";

import { LoadingOverlay } from "@/components/displays";
import {
  AcceptTermsInput,
  ModalDialogTerms,
  useModalDialogTerms,
} from "@/components/domains/registration";
import { ReCaptcha } from "@/components/feedbacks";
import { TextField } from "@/components/inputs";
import { signupEmail } from "@/generated/axios-functions/payseAPI";
import { getErrorMessages } from "@/utils/error";

import { RegistrationFormSchema, RegistrationFormSchemaValue } from "./schema";

export function RegistrationForm(): React.ReactNode {
  const router = useRouter();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<RegistrationFormSchemaValue>({
    resolver: zodResolver(RegistrationFormSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      hasAcceptedTerms: false,
      recaptchaToken: "",
    },
  });

  const { showTermsDialog, openTermsDialog, closeTermsDialog, onAcceptTerms } = useModalDialogTerms(
    {
      onAccept: () => {
        setValue("hasAcceptedTerms", true);
        trigger("hasAcceptedTerms");
      },
    }
  );

  const handleOnSubmit = useCallback(
    async (formValues: RegistrationFormSchemaValue) => {
      try {
        await signupEmail(formValues);
        router.push("/registration/thanks");
      } catch (error) {
        let firstErrMessage = getErrorMessages(error)[0];
        if (isAxiosError(error) && error.response?.status !== 422) {
          firstErrMessage = "エラーが発生しました。入力内容をご確認ください。";
        }

        setError("email", {
          type: "manual",
          message: firstErrMessage,
        });
      }
    },
    [router, setError]
  );

  return (
    <LoadingOverlay isLoading={isSubmitting}>
      <form id="registration" onSubmit={handleSubmit(handleOnSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              id="CustomerEmail"
              type="email"
              label="メールアドレス"
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="username"
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="recaptchaToken"
          control={control}
          render={({ field: { onChange } }) => (
            <ReCaptcha
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_CUSTOMER_SIGNUP_SITEKEY || ""}
              onChange={(token) => onChange(token)}
              onErrored={() => onChange(null)}
              onExpired={() => onChange(null)}
            />
          )}
        />
        <div className="text__center">
          <Controller
            name="hasAcceptedTerms"
            control={control}
            render={({ field: { value, onChange } }) => (
              <AcceptTermsInput value={value} onChange={onChange} onClickLink={openTermsDialog} />
            )}
          />
          {errors.hasAcceptedTerms && (
            <p className="text__red text__s mg__top__xs">{errors.hasAcceptedTerms.message}</p>
          )}
        </div>
        <div className="text__center pd__top__m">
          <button
            disabled={!isValid || isSubmitting}
            className={clsx("btn inline round text__black text__m", isValid ? "yellow" : "gray")}
            form="registration"
            type="submit"
          >
            送信する
          </button>
        </div>
      </form>

      <ModalDialogTerms
        open={showTermsDialog}
        closeModal={closeTermsDialog}
        onAcceptTerms={onAcceptTerms}
      />
    </LoadingOverlay>
  );
}
