"use client";
import clsx from "clsx";

import { LinkButton, Row } from "@/components";

import styles from "./MileProgramFooter.module.scss";

export function MileProgramFooter(): React.ReactNode {
  return (
    <section className={clsx(styles.container, "bg__gray")}>
      <Row align="center" justify="center" className="bg__gray">
        <LinkButton arrow="angle__left" color="border__bk" href="/mypage">
          マイページに戻る
        </LinkButton>
      </Row>
    </section>
  );
}
