"use client";
import clsx from "clsx";

import { MileRankButton } from "@/components/domains/mile";

import styles from "./RankSectionLinks.module.scss";
import { mileProgramSectionItems } from "../consts";

export function RankSectionLinks(): React.ReactNode {
  return (
    <section className={clsx(styles.container)}>
      <div className={styles.linksContainer}>
        {mileProgramSectionItems.map((section) => (
          <MileRankButton key={section.id} to={section.id}>
            {section.title}
          </MileRankButton>
        ))}
      </div>
    </section>
  );
}
