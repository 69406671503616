"use client";

import React from "react";

import clsx from "clsx";
import Link from "next/link";

import styles from "./EgiftLineup.module.scss";
import { egiftSections, egiftLineupItems } from "../consts";

export function EgiftLineup(): React.ReactNode {
  return (
    <section id={egiftSections.Lineup.id} className="bg__white pd__top__xl pd__bottom__xl">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="text__xxl text__bold text__center">{egiftSections.Lineup.title}</p>
            <p className="text__m text__center mg__top__l mg__bottom__l">
              以下のセット内容に、お好きな商品も追加いただけます。
            </p>
          </div>
        </div>
        <div className={clsx(styles.wrap, "row")}>
          {egiftLineupItems.map((set) => (
            <div
              key={set.name}
              className={clsx(styles.cell, "col-6 col-m-3 pd__bottom__l text__center")}
            >
              <img className="full__image" src={set.img} alt={set.name} />
              <p
                className={clsx(styles.productName, set.singleLine && styles.singleLine, "text__m")}
              >
                {set.name}
              </p>
              <p className="text__l text__bold mg__top__s">
                {set.price}
                <sup className="sup text__normal">※</sup>
              </p>
              {set.deliveryfee && <p className="text__s mg__bottom__m">送料・税込</p>}
              {!set.deliveryfee && <p className="text__s mg__bottom__m">送料無料・税込</p>}
              <Link href={set.link} className="btn yellow inline round text__black wsnr">
                ギフトを見る
              </Link>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <p className="text__s text__center">※1回のみ購入時の金額</p>
          </div>
        </div>
      </div>
    </section>
  );
}
