import clsx from "clsx";

import { Scroll } from "@/components/displays";
import { useParsedGetSubscription } from "@/queries";

import styles from "./CopyShareUrlButton.module.scss";

export function CopyShareUrlButton(): React.ReactNode {
  const { isLimitReachedInFreeShippingCampaign } = useParsedGetSubscription();
  if (isLimitReachedInFreeShippingCampaign) {
    return null;
  }

  return (
    <Scroll to="share" className={clsx("btn inline round yellow", styles.fixed)}>
      紹介用URLをコピーする
    </Scroll>
  );
}
